@import 'variaveis';

@import 'base';
@import 'header';
@import 'content';
@import 'footer';
@import 'menu';


/*===========================================
	BOOTSTRAP
============================================*/

@import 'bootstrap';

/*===========================================
	maginific
============================================*/

@import 'vendor/maginific-popup';

/*===========================================
	ALERTIFY
============================================*/

@import 'vendor/alertify';
@import 'vendor/nouislider';

/*===========================================
	RESPONSIVO
============================================*/

@import 'mobile';