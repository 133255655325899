#mobile-footer-menu{
    display: block;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 70px;
    background-color: $main-color;
    z-index: 1000;
    overflow: hidden;
    & > ul{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0;
        height: 70px;
        & > li{
            text-align: center;
            align-items: center;
            justify-content: center;
            flex: 1;
            border-right: 1px solid #fff;
            &:last-child{
                border-right: none;
            }
            &.fixed-menu-secondary-element{
                display: none;
                @media (min-width: 600px){
                    display: inline;
                }
            }
            & > a {
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                padding: 19px 19px 14px 19px;
                width: 100%;
                color: $branco;
                i{
                    font-size: 30px;
                }
            }
            .fixed-menu-label{
                color: $branco;
                display: block;
                font-size: 12px;
            }
        }
    }
    .bg-whatsapp{
        background-color: #5cb85c;
    }
    .btn-menu-mobile{
        padding: 5px 0 0 0;
        &.open{
            margin-top: -5px;
        }
    }
}
.menu-overlay {
    height: 100vh;
    width: 100vw;
    display: none;
    position: fixed;
    background-color: #333;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 97;
    &.show{
        display: block;
    }
}
.menu-top{
    float:right;
    background-color: $gray;
    .menu-top_content{
        padding: 12px 0px;

        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .menu-top-content_nav{
            min-width: 55%;

            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;

            margin: 0;

            .border-left{
                width: 1px;
                padding: 2px;
                border-left: solid 1px #b7b7b7;

            }

            .menu-top-content-nav_items{
                position: relative;
                line-height: 1em;
                padding-left: 10px;
                padding-right: 10px;


                &.lojista-out{
                    background-color: $secondary-color;
                    padding: 3px;
                    border-radius: 3px;
                    a {
                        color: #fff;
                        text-transform: none;
                    }
                }

                &.phone-item{
                    a{
                        font-size: 0.49em;
                        text-transform: capitalize;

                    }
                }
                a{
                    color: $preto;
                    text-transform: uppercase;
                    font-weight: bold;
                    text-decoration: none;
                    font-size: .8em;
                    @include transition(0.3s);
                    &:hover{
                        color: darken($main-color, 10%);
                    }

                    @media (max-width: 992px) {
                        font-size: 0.71em;
                    }
                }

                &:first-child{ padding-left: 0; }
                &:last-child{ padding-right: 0; }

                &:active, &:hover{

                    a{
                        color: $theme-color;
                        font-weight: bold;
                        transition: all 0s;
                    }
                }

                &.active{
                    a{
                        color: $theme-color;
                        font-weight: bold;
                        font-family: "OpenSansExtraBold";
                    }

                }

            }
        }

        @media (max-width: 768px) {
          border-top: none;
            flex-flow: column;
            .menu-top-content_nav{
                min-width: 100%;
            }

        }

        @media (min-width: 769px) and (max-width: 1024px) {
            font-size: .9em;
        }
    }

}

@media (min-width: 769px) {
    #mobile-footer-menu{
        display: none;
    }
    .menu-top-content-nav_items{
        &.phone-item{
            display: none;
            padding-top: 20px;
        }
    }
}
@media (max-width: 768px) {
    .menu-top{
        position: fixed;
        z-index: 98;
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;

        right: -100%;
        top: 57px;

        width: 80vw;

        &.menu-show{
            animation: slide-in 0.5s forwards;
            -webkit-animation: slide-in 0.5s forwards;
            &>.menu-container{
                height: calc(100vh - 120px);
                overflow: hidden;
            }
        }

        .menu-top_content{
            height: 100%;
            padding-top: 10px;
            overflow: scroll;

            flex-flow: column-reverse;
            justify-content: flex-end;

            .menu-top-content_nav{
                width: 100%;
                flex-flow: column;
                margin-top: 15px;
                font-size: 1.5em;

                .menu-top-content-nav_items{
                    width: 100%;
                    text-align: center;
                    border-bottom: 1px solid #666;
                    padding: 5px 0;
                    &:first-child{
                        border-top: 1px solid #666;
                    }
                    &.phone-item{
                        border: none;
                    }
                    a{
                        color: $branco;
                        display: block;
                    }
                }
            }

            .menu-top-content_search{
                min-width: 80%;
            }
        }

    }
    .barra-de-pesquisa .form-inline .form-group .input-group .input-group-addon .border-left{
        border: none;
    }
}
header .btn-menu-mobile {
    position: fixed;
    z-index: 100;
    top: 10px;
    right: 15px;
    padding: 10px 0;
}
.btn-menu-mobile{
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 30px;

    span{

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+1,ffffff+100&0+0,0.75+15,1+51,0.75+85,0+100 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.05) 1%, rgba(255,255,255,0.75) 15%, rgba(255,255,255,1) 51%, rgba(255,255,255,0.75) 85%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.05) 1%,rgba(255,255,255,0.75) 15%,rgba(255,255,255,1) 51%,rgba(255,255,255,0.75) 85%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.05) 1%,rgba(255,255,255,0.75) 15%,rgba(255,255,255,1) 51%,rgba(255,255,255,0.75) 85%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

        display: block;
        width: 30px;
        height: 4px;
        border-radius: 5px;
        margin-bottom: 5px;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
    }
    &.open{
        span{
            &:nth-child(1),
                &:nth-child(3){
                transform: translate(0px, 4px) rotate(-45deg) scalex(1.3);
                margin: 0;
            }
            &:nth-child(2){
                height: 0;
                margin: 0;
            }
            &:nth-child(3){
                transform: translate(0px, 0px) rotate(45deg) scalex(1.3);
            }
        }
    }

}

@keyframes slide-in {
    100% { right: 0%; }
}

@-webkit-keyframes slide-in {
    100% { right: 0%; }
}

@keyframes slide-out {
    0% { right: -0%; }
    100% { right: -100%; }
}

@-webkit-keyframes slide-out {
    0% { right: 0%; }
    100% { right: -100%; }
}
