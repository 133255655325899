footer{
    margin-top: 100px;
}
.whatsapp-fixed {
    position: fixed;
    top: 85%;
    right: 1%;
    padding: 10px;
    z-index: 10000000;
}
.oportunidades{
    padding:{
        top: 40px;
        bottom: 40px;
    };

    .titulo{
        display: inline-block;
        width: 100%;
        text-align: center;
        color: $branco;
        text-transform: uppercase;
        font:{
            font-weight: 400;
            font-size: 30px;
        };
        margin-bottom: 50px;
    }

    .oportunidades-list{
        display: flex;
        li{
            flex: 1;
            display: flex;
            margin: 10px 30px;
            a{
                padding: 30px 55px;
            }
            @media screen and (max-width: 991px){
                margin: 10px;
            }
        }

        @media screen and (max-width: 768px){
            display: block;
            li{
                display: block;
            }
        }
    }

    .botao-default{
        width: 100%;
        display: inline-block;
        background: $main-color;
        border-bottom: 5px solid $secondary-color;
        text-transform: uppercase;
        color: $branco;
        text-align: center;
        font:{
            size: 15px;
            weight: 700;
        };
        @include transition(0.3s);
        @include radius(10px);
        /*height: 150px;*/
        line-height: 22px;
        i{
            display: block;
            font-size: 36px;
            margin-bottom: 10px;
        }
        &:hover{
            background: $secondary-color;
        }
        &.ligamos-para-voce{
            background-size: cover;
        }
        &.nao-encontrou{
            background-size: cover;
        }
        &.compramos{
            background-size: cover;
        }
        div.linha {
            border-bottom: 3px solid #fff;
            padding-bottom: 15px;
            border-radius: 3px;
        }
    }
}

@media (max-width: 768px) {
    .oportunidades{
        .oportunidades-list{
            li{
                a{
                    padding: 10px 0px;
                }
            }
        }
        .botao-default{
            background-image: none !important;
            border-bottom: none;
            background-color: $main-color;
            border: solid 3px $main-color;
            border-radius: 20px;

            div.linha{
                border: none;
                padding-bottom: 0px;
                color: white;
            }

            i{
                display: inline;
                font-size: 24px;
                margin: 0;
                margin-right: 10px;
                padding-top: 0px;
            }
        }
    }
}


.box-menu-footer{
    background: $main-color;
    padding:{
        top: 30px;
        bottom: 30px;
    };
    .menu-footer{
        text-align: center;
        li{
            display: inline;
            padding-left: 12px;
            padding-right: 12px;
            a{
                color: $branco;
                text-transform: uppercase;
                font:{
                    weight: 900;
                    size: 14px;
                };
                @include transition(0.3s);
                &:hover{
                    color: $preto;
                }
            }
        }
    }
}



.box-newsletter{
    background: $theme-color;
    padding:{
        top: 30px;
        bottom: 30px;
    };
    .newsletter-block{
        padding: 10px 50px;
    }
    .nome-coluna{
        padding-left: 60px;
        color: $branco;
        display: block;
        width: 100%;

        h3{
            font:{
                weight: 700;
                size: 22px;
            };
        }
        p{
            font-size: 12px;
            margin: 5px 0px;
        }
        &.money-icon{
            background: transparent url('../img/icones/icon_money.png') no-repeat scroll left center;
        }
        &.cars-icon{
            background: transparent url('../img/icones/icon_carro.png') no-repeat scroll left center;
        }
    }

    .info-coluna{
        display: block;
        width: 100%;
        padding: 10px 60px;
        font:{
            weight: 700;
            size: 14px;
        };
        color: $branco;
    }
    .botao-default{
        display: inline-block;
        background: $secondary-color;
        text-transform: uppercase;
        color: $branco;
        text-align: center;
        padding: 6px 24px;
        @include transition(0.3s);
        @include radius(1px);
        &:hover{
            background: $main-color;
            border: 1px solid $secondary-color;
        }
    }

    .newsletter-form{
        display: flex;
        input{
            vertical-align: middle;
            display: flex;
            @include radius(0px);
            &[type="submit"]{
                width: 50px;
                background-color: $secondary-color;
                border: 0;
                color: $branco;
                font-size: 20px;
                line-height: 10px;
            }
            &[type="text"]{

            }
        }
    }
}




.box-assinatura{
    background: $branco;
    padding:{
        top: 13px;
        bottom: 13px;
    };

    .todos-os-direitos{
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    

        .copy{
            color: #363636;
            display: inline-block;
            padding-left: 10px;
            font:{
                weight: 300;
                size: 11px;
                style: italic;
            };
        }
    }

    .ass{
        text-align: right;
    }


}


#map{
    width: 100%;
    height: 380px;
    background: $preto;
}

  ul.lojas-mapa{
    display: flex;
    flex: auto;
    flex-direction: row;
    li{
        position: relative;
        flex: 1;
        a{
            display: block;
            padding: 10px 5px;
            font-size: 13px;
            color: $branco;
            background-color: #3f3f3f;
            text-align: center;
            img{
                float: left;
                margin: 2px 10px;
            }
            &.active, &:hover{
                background-color: $secondary-color;
            }
        }
        i{
            position: absolute;
            font-size: 22px;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.js-showmap{
  height:100%;
}
.maps-full{
    position: relative;
    padding-top: 30px;
    >.container{
        position: absolute;
        top: -56px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}
.map-content{
    display: none;
    iframe{
        width: 100%;
        height: 350px;
    }
    &.active{
        display: block;
    }
}
/*------------------------------------*
    #full-map-section
/*------------------------------------*/
.full-map-info{
    background-color: $main-color;
    padding: 25px 0;
    color: white;
    h3{
        text-transform: uppercase;
    }


.contact-menu .contact-menu__link{
     strong{
      color:white;
      }
}
.icon-phone{
    display: inline-block;
    height: 22px;
    width: 22px;
    background: url('../img/icones/icon_phone_orange.png');
    margin-right: 5px;
    position: relative;
    top: 5px;
}
.icon-whatsapp{
    display: inline-block;
    height: 22px;
    width: 22px;
    background: url('../img/icones/icon_whatsapp_green.png');
    margin-right: 5px;
    position: relative;
    top: 5px;
}
}
