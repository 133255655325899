// Cores
$branco: #fff;
$preto: #000;
$theme-color: #c20e2a;
$main-color: #c20e2a;
$main-color--lighter: lighten($main-color, 10%);
$secondary-color: #e40000;
$cta-color: #e40000;
$roxo: #272446;
$roxo-claro: #3d3871;

$gray: #b3b2b2;
$dark-gray: #58595b;

$cta-text-color: white;

$text-alt-color: #E74011;

// Fonts
$lato: 'Lato', sans-serif;


// Mixins
@mixin radius ($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition ($all){
	-webkit-transition: all ($all) ;
    -moz-transition: all ($all) ;
    -o-transition: all ($all) ;
    transition: all ($all);
}


@mixin box-shadow ($style){
	-moz-box-shadow: ($style);
	-webkit-box-shadow: ($style);
	box-shadow: ($style);
}
